<template>
  <el-row :gutter="20">
    <el-col
      :xs="12"
      :sm="12"
      :md="12"
      :lg="12"
      :xl="12"
    >
      <div class="heading">
        <h1>{{ headingName }}</h1>
      </div>
    </el-col>
    <el-col
      :xs="12"
      :sm="12"
      :md="12"
      :lg="12"
      :xl="12"
    >
      <div class="topsearch">
        <el-button
          type="warning"
          @click="lead()"
        >
          <i class="fa fa-angle-left"></i>
          <i class="fa fa-angle-left" style="margin-right: 5px;"></i> Leads
        </el-button>
      </div>
    </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="24"
      :xl="24"
    >
      <el-tabs type="border-card">
        <el-tab-pane label="Profile">
          <el-form
            ref="ruleFormProfile"
            :model="profileData"
            status-icon
            :rules="rules"
            label-width="180px"
            class=" login-form"
            :data="profileData"      
            :label-position="labelPosition" 
          >
            <div class="panel panel-default">
              <div
                class="panel-title"
                style="padding-bottom: 25px;margin-bottom: 20px;"
              >
                <i class="fa fa-bars" /> Contact Detail<el-button
                  style="float: right;"
                  type="success"
                  @click="submitFormProfile('ruleFormProfile')"
                >
                  Save
                </el-button>
              </div>
    

              <el-row :gutter="20">
                <el-col
                  :xs="24"
                  :sm="24"
                  :md="24"
                  :lg="24"
                  :xl="24"
                >
                  <div v-if="showAlertCustomer">
                    <el-alert
                      v-if="!customer_id"
                      title="Lead Added Successfully"
                      type="success"
                      effect="dark"
                    />
                    <el-alert
                      v-if="customer_id"
                      title="Customer Updated Successfully"
                      type="success"
                      effect="dark"
                    />
                  </div>
                </el-col>
              </el-row>
 
              <el-form-item
                label="Name"
                prop="name"
              >
                <el-input
                  v-model="profileData.name"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
              <el-form-item
                label="Phone"
                prop="phone"
              >
                <el-input
                  v-model="profileData.phone"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
              <el-form-item
                label="Email"
                prop="email"
              >
                <el-input
                  v-model="profileData.email"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
              <el-form-item
                label="Password"
                prop="password"
              >
                <el-input
                  v-model="profileData.password"
                  type="password"          
                  autocomplete="off"
                />
              </el-form-item>

              <el-form-item
                label="Confirm Password"
                prop="confirm_password"
              >
                <el-input
                  v-model="profileData.confirm_password"
                  type="password"
                  autocomplete="off"
                />
              </el-form-item>
              <div v-if="showAlertMatch">
                <el-alert
                  title="Password and Confirm Password must match."
                  type="error"
                  effect="dark"
                />
              </div>
              <el-form-item>
                <input
                  id="customer_id"
                  v-model="customer_id"
                  name="customer_id"
                  type="hidden"
                >
              </el-form-item>
            </div>
            <div class="panel panel-default"> 
              <div class="panel-title">
                <i class="fa fa-bars" />  Address Information
              </div>
              <el-form-item
                label="Address"
                prop="address"
              >
                <el-input
                  v-model="profileData.address"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>

              <el-form-item
                label="Postcode"
                prop="postalcode"
              >
                <el-input
                  v-model="profileData.postalcode"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
      
              <el-form-item
                label="Country"
                prop="country"
              >
              <el-select v-model="profileData.country_name" placeholder="Select Country">
              <el-option
              v-for="item in countries"
              :key="item.value"
              :value="item.value"
              :label="item.label"
              @click="getStates(item.value)"
            ></el-option>
          </el-select>
              </el-form-item>

              <el-form-item
                label="State"
                prop="state"
              >
              <el-select v-model="profileData.state_name" placeholder="Select Region / State">
              <el-option              
              v-for="item in states"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              @click="getCities(item.value)"
            />
          </el-select>
              </el-form-item>

              <el-form-item
                label="City"
                prop="city"
              >
                <el-select v-model="profileData.city_name" placeholder="Select Region / State">
              <el-option              
              v-for="item in cities"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
              </el-form-item>
            </div>
            <div class="panel panel-default"> 
              <div class="panel-title">
                <i class="fa fa-bars" /> Additional Information
              </div>
              <el-form-item
                label="Website"
                prop="website"
              >
                <el-input
                  v-model="profileData.website"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>

              <el-form-item
                label="Subscribe"
                prop="Subscribe"
              >
                <el-input
                  v-model="profileData.subscribe"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>

              <el-form-item
                label="Newsletter"
                prop="newsletter"
              >
                <el-input
                  v-model="profileData.newsletter"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>

              <el-form-item
                label="Status"
                prop="status"
              >
                <el-input
                  v-model="profileData.status"
                  type="text"
                  autocomplete="off"
                />
              </el-form-item>
            </div>
            <div class="panel panel-default"> 
              <div class="panel-title">
                <i class="fa fa-bars" /> System Information
              </div>
              <el-row :gutter="20">
                <el-col
                  :xs="12"
                  :sm="12"
                  :md="12"
                  :lg="12"
                  :xl="12"
                >
                  <div class="heading">
                    IP Address
                  </div>
                </el-col>
                <el-col
                  :xs="12"
                  :sm="12"
                  :md="12"
                  :lg="12"
                  :xl="12"
                >
                  212.102.33.167
                </el-col>
              </el-row>
            </div>  
            <div class="panel panel-default"> 
              <div class="panel-title">
                <i class="fa fa-bars" /> Offers
              </div>
              No Offers Available
            </div> 
          </el-form>
        </el-tab-pane>

        <el-tab-pane label="Message">
          <div class="panel panel-default">    
            <div
              v-for="item in showmessagesData"
              :key="item.message"
              style="width: 100%;list-style: none;color: #000;"
            >
              <div class="titledate">
                Received on:  {{ item.date }}<span class="pull-right text-right" /><div class="note_buttons" />
              </div>
              <h4>Contact Us </h4>  
              {{ item.message }}
              <br>
              <br> 
            </div>
            <div v-if="ismessages">     
              <el-button
                type="danger"
                style="width:100%;text-align: left;"
              >
                Warning: There  is no lead!!!
              </el-button>
            </div>
          </div>
        </el-tab-pane>

        <el-tab-pane label="Notes">
          <div class="panel panel-default">
            No result found
          </div>
        </el-tab-pane>

        <el-tab-pane label="Reminders">
          <div class="panel panel-default">
            No result found
          </div>
        </el-tab-pane>
  
        <el-tab-pane label="Calls">
          <div class="panel panel-default">
            No result found
          </div>
        </el-tab-pane>

        <el-tab-pane label="Favourities">
          <div class="panel panel-default">
            <div class="panel-title">
              <i class="fa fa-bars" /> Favourite Properties
            </div>
			<h4>My Listings</h4> 
			<template>
  <el-table :data="showfavouritesData" style="width: 100%">
    <el-table-column prop="id" label="Name" width="120" />
    <el-table-column fixed="right" label="Operations" width="120">
      <template #default>
        <el-button link type="primary" size="small"
          >Detail</el-button
        >
        <el-button link type="primary" size="small">Edit</el-button>
      </template>
    </el-table-column>
  </el-table>
</template>
            <div
              v-for="item in showfavouritesData"
              :key="item.message"
              style="width: 100%;list-style: none;color: #000;">               
              {{ item.message }}
              <br>
              <br> 
            </div>
            <div v-if="isfavourites">     
              <el-button
                type="danger"
                style="width:100%;text-align: left;"
              >
                Warning: There  is no lead!!!
              </el-button>
            </div>
			<h4>DDF Listings</h4> 
            <div
              v-for="item in showfavouritesDataddf"
              :key="item.message"
              style="width: 100%;list-style: none;color: #000;">               
              {{ item.message }}
              <br>
              <br> 
            </div>
            <div v-if="isfavouritesddf">     
              <el-button
                type="danger"
                style="width:100%;text-align: left;"
              >
                Warning: There  is no lead!!!
              </el-button>
            </div>
          </div>
        </el-tab-pane>

        <el-tab-pane label="Timeline">
          <div class="panel panel-default">
            Timeline
          </div>
        </el-tab-pane>

        <el-tab-pane label="Team">
          <div class="panel panel-default">
            Team
          </div>
        </el-tab-pane>

        <el-tab-pane label="Tag">
          <div class="panel panel-default">
            Tag
          </div>
        </el-tab-pane>
  
        <el-tab-pane label="Social Media">
          <div class="panel panel-default">
            Social Media
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-col>
  </el-row>
</template>
 <script>
import CustomerService from "@/api/customer.service";
import CountryService from "@/api/country.service";

  export default {
    data() {
      var checkName = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Name.'));
        } else {
          callback();
        }
      };
      var checkEmail = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Email.'));
        } else {
          callback();
        }
      };
      var checkPassword = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Password.'));
        } else {
          callback();
        }
      };
       var checkPasswordConfirm  = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Password Confirm.'));
        } else {
          callback();
        }
      };
      return {
        showAlertCustomer: false,
        showAlertMatch:false,
        ismessages:false,
        labelPosition:"left",
        customer_id: this.$route.params.customer_id,
        headingName:null,
        countries: [],
        states:[],
        cities:[],
        profileData: {
          name: null,
          email: null,
          phone: null,
          password:null,
          confirm_password:null,
          address:null,
          country: null,
          country_name: null,
          state: null,
          state_name:null,
          city: null,
          city_name:null,
          website:null,
          subscribe:null,
          newsletter:null,
          status:null,
        },
        showmessagesData: [],
        showfavouritesData: [],
        showfavouritesDataddf: [],
        rules: {
          name: [
            { validator: checkName, trigger: 'blur' }
          ],
          email: [
            { validator: checkEmail, trigger: 'blur' }
          ],
         /* password: [
            { validator: checkPassword, trigger: 'blur' }
          ],
          confirm_password: [
            { validator: checkPasswordConfirm, trigger: 'blur' }
          ],*/
        }
      };
    },
     watch: {
      profileData: function () {
        if(!this.profileData.country_name && this.profileData.country) {
          this.profileData.country_name = this.extractNameById(this.profileData.country, this.countries);
        }
        if(!this.profileData.state_name && this.profileData.state) {
          this.profileData.state_name = this.extractNameById(this.profileData.state, this.states);
        }
        if(!this.profileData.city_name && this.profileData.city) {
          this.profileData.city_name = this.extractNameById(this.profileData.city, this.cities);
        }
      },
      countries: function () {
        this.profileData.country_name = this.extractNameById(this.profileData.country, this.countries);
      }
      ,states: function () {
        this.profileData.state_name = this.extractNameById(this.profileData.state, this.states);
      },
      cities: function () {
        this.profileData.city_name = this.extractNameById(this.profileData.city, this.cities);
      },
      
    },
	mounted() {
    this.getcountries();
    if(this.customer_id){
      this.get();
      this.showmessages();
      this.favourites('listing');
      this.favouritesddf('ddf');
    }
  },
    methods: {
      async getCities(statsid){
        return CountryService.getCities(1,statsid).then(response => {
          this.cities=response.data.data;
        });
      },
     async getStates(countryid){
        return CountryService.getStates(1,countryid).then(response => {
          this.states=response.data.data;
        });
      },
      async getcountries(){
       return CountryService.getcountries().then(response => {
         this.countries=response.data.data;
      });
      },
      extractNameById(needle, hayStack) {
        let val = null;
        val = hayStack.filter(function(element){
          if(element['value'] == Number(needle)) {
            return element['label'];
          }
        });
        if(val.length) {
          return val[0]['label'];
        }
        return null;
      },
       submitFormProfile(ruleFormProfile) {    
        this.profileData.country = this.profileData.country_name;
        this.profileData.state = this.profileData.state_name;
        this.profileData.city = this.profileData.city_name;   

        this.$refs[ruleFormProfile].validate((valid) => {
         if(this.profileData.password!==this.profileData.confirm_password){
            this.showAlertMatch=true;
             //alert("Password and Confirm Password must match.");
          }else if (valid && this.customer_id) {
            return CustomerService.update(this.customer_id, this.profileData).then(response => {
              this.showAlertCustomer=true;
              return response;
            });
          }else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      handleFileUpload(){

      },
    showmessages(){
       this.ismessages=false;
      return CustomerService.getleads(this.customer_id).then(response => {
        this.dialogVisibleMessages=true;
        this.showmessagesData =response.data.data;
        if(response.data.data.length==0){
          this.ismessages=true;
        }
      });
    },
	favourites(istype){
       this.isfavourites=false;
      return CustomerService.getfavouriteslistings(this.customer_id,istype).then(response => {
        this.showfavouritesData =response.data.data;
        if(response.favourites==0){
          this.isfavourites=true;
        }
      });
    },
	favouritesddf(istype){
       this.isfavouritesddf=false;
      return CustomerService.getfavouriteslistings(this.customer_id,istype).then(response => {
        this.showfavouritesDataddf =response.data.data;
        if(response.favourites==0){
          this.isfavouritesddf=true;
        }
      });
    },
      lead(){
        this.$router.push("/leads");
      },
	get(){
    return CustomerService.get(this.customer_id).then(response => {
         this.profileData = response.data.data;
         this.headingName = response.data.data.name;
        if(this.profileData.country){
          this.getStates(this.profileData.country);
        }
         if(this.profileData.state){
          this.getCities(this.profileData.state);
        }   
      });
	}
    }
  }

</script>
<style lang="scss">
.el-select{
  width: 100%;
}
  .panel-title{
      border-bottom: 1px solid #9a83835c;
      padding-bottom: 10px;
      margin-bottom: 10px; 
      text-align: left;
  }
 .titledate {
    color: #2E7D32;
    font-size: 18px;
    padding: 10px 0px 0px 0px;
}
.el-dialog__body{
  text-align: left;
}
 .el-dialog__title{
    float: left;
 }
 .lead_tagbtn{   
    color: rgb(255, 255, 255);
    width: 136px;
    margin-top: 4px;
    margin-left: 0px !important;
  }
 .take_action {
    cursor: pointer;
    color: rgb(35, 166, 137);
}
 .div_top{
   width: 100%;
   padding: 5px 0px;
 }
 .div_top .el-button--mini{
  padding: 3px 5px;
  color: #000000;
  border: 1px solid black;
}
#actions .el-button--mini{
  padding: 3px 7px;
  margin: 2px;
}
 .panel {
    text-align: left;
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 17px;
    display: inline-block;
    background: #fff;
    border: 1px solid #E6E9ED;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    opacity: 1;
    transition: all .2s ease;
}
.alpha{cursor: pointer;padding-right:5px;}
.activealpha{color: #26B99A;}
.alphabet{margin-top: 15px;padding-left:0px !important;padding-right:0px !important}
 .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px;
}
  .el-input--mini .el-input__inner {
    height: 40px;
}
.well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 5%);
}
.el-form--label-top .el-form-item__label{
      float: left !important;
      font-size: 13px;
      padding: 0px;
}
  .topsearch .el-input {
      width: 500px;
      }
  .heading {
    text-align: left;
    width: 100%;
    line-height: 2;
  }
  .el-table .tableHeading {
    background: #565656 !important;
    text-transform: uppercase;
    font-size :.657rem;
    letter-spacing :.5px;
    color:#ffffff;
    font-family: 'Montserrat,Helvetica,Arial,serif';    
  }
.el-table .success-row {
    background: #f0f9eb61;
  }
 div .topsearch {
    float: right;
    display: inline-flex;
    padding: 15px 0px;
  }
div .topsearch .el-input__inner{
  padding-right:10px;
  height: 40px;
  width: 500px;
}
div .topsearch .el-input{
  padding-right:10px;
}
.pagination{ 
  padding: 12px 5px;
  float: right;
}
</style>
