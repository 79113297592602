import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_BASE_API

class CustomerService {   
	get(id) {
		return axios.get(API_URL + 'customer/'+id, { headers: authHeader() });
    }
    getleads(customer_id) {
      return axios.get(API_URL + 'getleads/'+customer_id, { headers: authHeader() });
  }
    getfavouriteslistings(customer_id,type) {
      return axios.get(API_URL + 'getfavouriteslistings/'+customer_id+'/'+type, { headers: authHeader() });
	}
    delete(customer_id) {
		return axios.post(API_URL + 'customer/delete/',customer_id, { headers: authHeader() });
    }    
    add(lead) {
        return axios.post(API_URL + 'customer/add', lead, { headers: authHeader() });
    }
    update(customer_id, customer) {
        return axios.post(API_URL + 'customer/'+customer_id, customer, { headers: authHeader() });
    }
}
export default new CustomerService();